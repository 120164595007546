<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/project/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div class="title">{{info.project_title}}</div>
            <div class="date">{{$t('project.releaseTime')}}：{{info.add_time}}</div>
            <center v-html="info.project_desc"></center>
            <div class="pinglun" v-if="this.$i18n.locale == 'zh'">
                <div class="pltitle">{{$t('pinglun.fabiaopinglun')}}</div>
                <div class="plinput">
                    <el-input type="textarea" :rows="5" :placeholder="$t('pinglun.shuodianshenme')" v-model="pingluncontent"></el-input>
                    <div style="display: flex;justify-content: flex-end;"><div class="plbtn" @click="pinglunbtn">{{$t('pinglun.pinglun')}}</div></div>
                </div>
                <div class="pllist">
                    <div class="allpltitle">{{$t('pinglun.quanbupinglun')}}<span class="plnum">{{pinglunlist.length}}</span></div>
                    <div v-for="(item,index) in pinglunlist" :key="index">
                        <div class="plitem">
                            <div class="plimage">
                                <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="item.user_image"></el-image>
                            </div>
                            <div class="plright">
                                <div class="plname">{{item.user_name}}</div>
                                <div class="plcontent">{{item.content}}</div>
                                <div class="ploption">
                                    <div class="pldate">{{item.addtime}}</div>
                                    <div class="plicon" style="display:flex">
                                        <div @click="huifu(item)" style="margin-top:2px;">
                                            <el-image :src="require('@/assets/huifu.png')"></el-image>
                                        </div>
                                        <div style="margin-left:25px;margin-top:2px;" @click="dianzan1(item)">
                                            <el-image :src="item.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                        </div><span style="margin-left:5px" :style="{color:item.like ? '#FAD20C' : ''}">{{item.like_num}}</span>
                                    </div>
                                </div>
                                <div class="huifu" v-if="item.textarea">
                                    <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                    <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,2,item.id)">{{$t('pinglun.huifu')}}</div></div>
                                </div>
                                <div v-for="(items,indexs) in item.get_reply" :key="indexs" v-show="item.allopen || indexs<3">
                                    <div class="plitem">
                                        <div class="plimage">
                                            <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="items.user_image"></el-image>
                                        </div>
                                        <div class="plright">
                                            <div class="plname">{{items.user_name}} <span v-if="items.form_name">->{{items.form_name}}</span></div>
                                            <div class="plcontent">{{items.content}}</div>
                                            <div class="ploption">
                                                <div class="pldate">{{item.addtime}}</div>
                                                <div class="plicon" style="display:flex">
                                                    <div @click="huifu(items)" style="margin-top:2px;">
                                                        <el-image :src="require('@/assets/huifu.png')"></el-image>
                                                    </div>
                                                    <div style="margin-left:25px;margin-top:2px;" @click="dianzan2(items)">
                                                        <el-image :src="items.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                                    </div><span style="margin-left:5px" :style="{color:items.like ? '#FAD20C' : ''}">{{items.like_num}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="huifu" v-if="items.textarea">
                                        <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                        <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,1,items.id)">{{$t('pinglun.huifu')}}</div></div>
                                    </div>
                                </div>
                                <div style="font-size: 14px;color: #4E4D47;margin-top:15px" v-if="!item.allopen && item.get_reply.length>3">共{{item.get_reply.length}}条评论，<span style="color: #0052D9;cursor:pointer" @click="showall(item)">点击查看</span></div>
                            </div>
                        </div>
                        <el-divider></el-divider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { projectInfo,commentList,commentReplyAdd,commentAdd,commentLike,commentReplyLike } from "@/api/api"; 
export default {
    data(){
        return{
            project_id:'',
            info:{},
            title:'',
            pingluncontent:'',
            huifucontent:'',
            pinglunlist:[],
            pingluntotal:0,
            hfname:'',
            userinfo:{}
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    created(){
        this.userinfo = sessionStorage.getItem('userinfo') ? sessionStorage.getItem('userinfo') : ''
        if(this.userinfo != ''){
            this.userinfo = JSON.parse(this.userinfo)
        }
        let project_id  = this.$route.params.id
        this.project_id = project_id
        this.getList()
        this.getpinglun()
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        async getList(){
           const {data:res} = await projectInfo(this.project_id)
           this.info = res
           this.title = res.project_title+'-'+this.$t('seo.indextitle')
        },
        async getpinglun(){
            let user_id = ''
            if(this.userinfo != ''){
                user_id = this.userinfo.userid
            }
            const {data:res} = await commentList({topic_id:this.project_id,topic_type:4,user_id:user_id})
            let data = this.initdata(res.list)
            this.pinglunlist = data
            this.pingluntotal = res.total
        },
        showall(item){
            this.pinglunlist = this.initdata(this.pinglunlist)
            item.allopen = true
        },
        initdata(data){
            data.forEach((item,index)=>{
                item.textarea = false
                item.allopen = false
                if(item.get_reply.length>0){
                    item.get_reply.forEach((items,indexs)=>{
                        items.textarea = false
                    })
                }
            })
            return data
        },
        jiaoyan(){
            this.$confirm(this.$t('loginerror'), this.$t('notLogIn'), {
            confirmButtonText: this.$t('logIn'),
            cancelButtonText: this.$t('close'),
            customClass: 'myConfirm',
            type: 'warning'
            }).then(() => {
                this.$router.push('/login')
            }).catch(() => {
            });
        },
        huifu(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                this.huifucontent  = ''
                this.hfname = item.user_name
                this.pinglunlist = this.initdata(this.pinglunlist)
                item.textarea = true
            }
        },

        async pinglunbtn(){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                let data = {
                    topic_id:this.project_id,
                    topic_type:4,
                    content:this.pingluncontent,
                    user_name:this.userinfo.name,
                    user_id:this.userinfo.userid,
                    user_image:this.userinfo.photo
                }
                const {data:res} = await commentAdd(data)
                this.pingluncontent = ''
                this.$message.success('评论成功');
                this.getpinglun()
            }
        },
        async huifubtn(comment_id,reply_type,reply_id){
            let data = {
                comment_id:comment_id,
                reply_type:reply_type,
                reply_id:reply_id,
                content:this.huifucontent,
                user_name:this.userinfo.name,
                user_id:this.userinfo.userid,
                user_image:this.userinfo.photo
            }
            const {data:res} = await commentReplyAdd(data)
            this.$message.success('回复成功');
            this.getpinglun()
        },
        async dianzan1(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentLike(data)
            item.like = true
            item.like_num = item.like_num+1
        },
        async dianzan2(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentReplyLike(data)
            item.like = true
            item.like_num = item.like_num+1
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin: 0 auto;
        margin-top: 1%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .title{
        text-align: center;
        color: #333333;
        font-size: 30px;
        font-weight: bold;
    }
    .date{
        margin-top: 10px;
        color: rgba(51, 51, 51, 0.4);
        text-align: center;
    }
    .pinglun{
        .pltitle{
            font-size: 22px;
            font-weight: bold;
            color: #333333;
        }
        .plinput{
            margin-top: 20px;
            .plbtn{
                width: 132px;
                height: 48px;
                background: #0052D9;
                text-align: center;
                line-height: 48px;
                color: #fff;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .pllist{
            .allpltitle{
                font-weight: bold;
                color: #4E4D47;
                .plnum{
                    color: rgba(78, 77, 71, 0.5);
                    margin-left: 10px;
                }
            }
            .plitem{
                display: flex;
                margin-top: 15px;
                .plimage{
                    margin-top: 5px;
                }
                .plright{
                    width: 100%;
                    margin-left: 10px;
                    .plname{
                        font-size: 14px;
                        font-weight: bold;
                        color: #4E4D47;
                    }
                    .plcontent{
                        margin-top: 10px;
                        font-size: 14px;
                        color: #4E4D47;
                    }
                    .ploption{
                        display: flex;
                        justify-content: space-between;
                        .pldate{
                            margin-top: 10px;
                            font-size: 13px;
                            color: rgba(78, 77, 71, 0.4);
                        }
                        .plicon{
                            cursor: pointer;
                            font-size: 16px;
                            color: rgba(78, 77, 71, 0.4);
                        }
                    }
                    .huifu{
                        margin-top: 10px;
                        .hfbtn{
                            width: 80px;
                            height: 30px;
                            border: 1px solid #0052D9;
                            text-align: center;
                            line-height: 30px;
                            margin-top: 10px;
                            color: #0052D9;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                }
                
            }
        }
    }
</style>
<style scoped>
    .pinglun >>> .el-textarea__inner{
        background: #F9F9F9;
    }
</style>